import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'next-i18next';
import { useTheme } from 'styled-components';

import { getProgressColor } from '@utils/coupons';

import PriceText from '@common/components/PriceText';
import ProgressBar from '@common/components/ProgressBar';
import { VOUCHER_TYPE } from '@common/constants/vip';
import {
  GiftCardSubtitle,
  RemovablePartWrapper,
  TimeleftDays,
  TimeleftTitle,
  WrapperSmaller,
} from '../RemovablePart.styled';

const TOTAL_DAYS = 90;

const RemovablePartActive = ({ daysLeft, title, totalDays, type }) => {
  const { colors } = useTheme();
  const progress = daysLeft * (100 / totalDays);
  const color = useMemo(
    () => getProgressColor(type, type === VOUCHER_TYPE.BON ? daysLeft : progress),
    [type, progress, daysLeft]
  );

  return (
    <WrapperSmaller data-testid="validCouponBottom">
      <RemovablePartWrapper>
        <GiftCardSubtitle>
          <PriceText>{title}</PriceText>
        </GiftCardSubtitle>

        <TimeleftTitle>
          <Trans
            i18nKey="voucher.daysLeft"
            defaults="Noch <0>{{daysLeft}}</0> Tage"
            values={{ daysLeft }}
            components={[<TimeleftDays key="voucher.daysLeft" />]}
          />
        </TimeleftTitle>
        <ProgressBar color={color}
progress={progress}
backgroundColor={colors.grey[40]} />
      </RemovablePartWrapper>
    </WrapperSmaller>
  );
};

RemovablePartActive.propTypes = {
  daysLeft: PropTypes.number,
  title: PropTypes.string,
  totalDays: PropTypes.number,
  type: PropTypes.string,
};

RemovablePartActive.defaultProps = {
  daysLeft: undefined,
  title: undefined,
  totalDays: TOTAL_DAYS,
  type: undefined,
};

export default RemovablePartActive;
