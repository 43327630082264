import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';
import { COUPON_DOT_PATTERN } from '@common/images';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: ${({ isButton }) => isButton && 'none'};
  ${({ theme }) => theme.getFluidAttribute('min-height', 15, 22.2)};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 3)};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: transparent;
  color: white;
  cursor: ${({ isButton }) => isButton && 'pointer'};
`;

export const DotsRow = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  ${({ isAtTop }) => (isAtTop ? 'top: 0;' : 'bottom: 0;')};
  z-index: 3;
  width: 100%;
  height: 0.4rem;
  background-image: ${`url(${COUPON_DOT_PATTERN})`};
  background-size: 0.8rem;
  background-position-y: ${({ isAtTop }) => (isAtTop ? ' bottom' : 'top')};

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: ${getThemeColor('grey.100')};
    background-size: 1.6rem;
    background-position-y: 50%;
  }

  &::before {
    left: 0;
    transform: translateY(${({ isAtTop }) => (isAtTop ? '-50%' : '-25%')}) translateX(-50%);
  }

  &::after {
    right: 0;
    transform: translateY(${({ isAtTop }) => (isAtTop ? '-50%' : '-25%')}) translateX(50%);
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: rgba(0, 0, 0, 0.75);
  color: ${getThemeColor('grey.100')};
  cursor: pointer;
`;

export const BackdropText = styled(Typography).attrs({ variant: 'paragraph1' })`
  margin: 0;
  padding: 2.5rem 2rem 3rem;
`;
